<template>
  <DropdownList
    v-model="isShown"
    :data
    :size
    :disabled="isDropdownDisabled"
    :current-value="modelValue"
    :popper-class
    @click:item="handleClickItem"
    @hide="handleHide"
    @is:end-of-list="handleEndOfList"
  >
    <template #default="{ show, toggle, icon, isDropdownActive }">
      <InputText
        :model-value="inputValue"
        :data-refid
        :label
        :disabled
        :readonly
        :size
        :error
        :silent
        :placeholder
        class="ui-input-autocomplete"
        :class="inputClasses"
        @click="show"
        @focus="handleFocus"
        @blur="handleBlur"
        @click:enter="handleClickEnter($event, isDropdownActive)"
        @click:tab="handleClickTab"
        @keydown="handleKeyDown"
        @ready="handleReady"
        @update:model-value="handleUpdateModelValue"
      >
        <template v-if="$slots.leading" #leading>
          <slot name="leading" />
        </template>
        <template v-if="$slots.trailing" #trailing>
          <slot name="trailing" />
        </template>
        <template v-else-if="isIconVisible" #trailing>
          <div class="ui-input-autocomplete__toggler" @click.stop="toggle">
            <component :is="icon" aria-hidden="true" />
          </div>
        </template>
      </InputText>
    </template>
    <template v-if="isNoResultVisible" #popper>
      <div class="ui-input-autocomplete-popper__no-result">
        {{ noResultLabel }}
      </div>
    </template>
    <template v-if="isCreateButtonVisible" #after>
      <div class="ui-input-autocomplete-popper__create-button">
        <Button
          ref="createButtonRef"
          :label="`New: ${searchValue}`"
          :size
          full
          @keydown="handleCreateButtonKeyDown"
          @click="handleCreateButtonClick"
        />
      </div>
    </template>
    <template v-if="$slots.item" #item="slotProps">
      <slot name="item" v-bind="slotProps" />
    </template>
  </DropdownList>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef, watch } from 'vue'
import { watchPausable, whenever } from '@vueuse/core'

import { DropdownListData, InputSize } from '@types'

import { useLocale } from '@/plugins/i18n'

import Button from '../Button/Button.vue'
import DropdownList from '../Dropdown/DropdownList.vue'
import InputText from './Text/Text.vue'

type Props = {
  data: DropdownListData[]

  dataRefid?: string

  label?: string
  placeholder?: string

  size?: InputSize
  popperClass?: string
  error?: string
  silent?: boolean

  disabled?: boolean
  readonly?: boolean

  dynamic?: boolean
  fetchable?: boolean
}

type Emits = {
  focus: [event: HTMLInputElement]
  blur: [event: HTMLInputElement]
  ready: [event: HTMLInputElement]
  'click:enter': [event: KeyboardEvent]
  'click:tab': [event: KeyboardEvent]
  'click:new': [data: string, callback: () => void]
  'fetch:data': [data: string]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string | undefined>({ required: true })

defineExpose({
  setSearchValue(value: string) {
    handleUpdateModelValue(value)
  },
})

const { t } = useLocale('components.UI.Input')

const createButtonRef = useTemplateRef('createButtonRef')

const inputRef = ref<HTMLInputElement>()
const isShown = ref(false)

const inputValue = ref('')
const searchValue = ref()

const buttonResumeFunction = ref<() => void>()

const data = computed(() => {
  return searchValue.value && !props.fetchable
    ? props.data.filter(item =>
        item.label.toLowerCase().includes(searchValue.value.toLowerCase()),
      )
    : props.data
})

const popperClass = computed(() => {
  return {
    'ui-input-autocomplete-popper': true,
    [`${props.popperClass}`]: !!props.popperClass,
  }
})

const isIconVisible = computed(() => !props.readonly)

const isDropdownDisabled = computed(() => props.disabled || props.readonly)

const inputClasses = computed(() => ({
  'ui-input-text--active': isShown.value,
}))

const placeholder = computed(() => props.placeholder || t('Find an option'))

const isNoResultVisible = computed(() => {
  return (
    (!props.dynamic && data.value.length === 0) ||
    (props.dynamic && !searchValue.value && data.value.length === 0)
  )
})

const noResultLabel = computed(() => {
  return props.dynamic && searchValue.value
    ? t('Enter to create')
    : t('Nothing to show')
})

const searchedItem = computed(() => {
  return (
    searchValue.value &&
    props.data.find(
      item => item.label.toLowerCase() === searchValue.value.toLowerCase(),
    )
  )
})

const isCreateButtonVisible = computed(() => {
  return props.dynamic && searchValue.value && !searchedItem.value
})

const handleClickItem = (value: string) => {
  modelValue.value = value
}

const handleBlur = (el: HTMLInputElement) => {
  emit('blur', el)
}

const handleFocus = (el: HTMLInputElement) => {
  emit('focus', el)
}

const handleReady = (el: HTMLInputElement) => {
  inputRef.value = el
  emit('ready', el)
}

const handleCreateButtonKeyDown = (event: KeyboardEvent) => {
  switch (event.key) {
    case 'ArrowUp':
      event.stopPropagation()
      buttonResumeFunction.value?.()
      setTimeout(() => {
        inputRef.value?.select()
      }, 0)
      break
    case 'ArrowDown':
      event.stopPropagation()
    default:
      break
  }
}

const handleCreateButtonClick = () => {
  handleCreate()
}

const handleEndOfList = (unselect: () => void, resume: () => void) => {
  if (createButtonRef.value) {
    createButtonRef.value.focus()
    buttonResumeFunction.value = resume
    unselect()
  }
}

const handleCreate = () => {
  emit('click:new', searchValue.value, () => {
    isShown.value = false
    setTimeout(() => {
      inputRef.value?.focus()
    }, 0)
  })
}

const handleClickEnter = (event: KeyboardEvent, isDropdownActive: boolean) => {
  if (isDropdownActive) return
  if (isDropdownDisabled.value) {
    emit('click:enter', event)
    return
  }
  const data = searchedItem.value
  if (data) {
    isShown.value = false
    event.stopPropagation()
    modelValue.value = data.value
    inputValue.value = data.label
  } else if (props.dynamic) {
    handleCreate()
  } else {
    isShown.value = false
    emit('click:enter', event)
  }
}

const handleClickTab = (event: KeyboardEvent) => {
  if (isShown.value) return
  emit('click:tab', event)
}

const handleKeyDown = (e: KeyboardEvent) => {
  if (isShown.value) return
  switch (e.key) {
    case 'ArrowDown':
    case 'ArrowUp':
      isShown.value = true
      break
  }
}

const handleHide = () => {
  inputRef.value?.focus()
}

const handleUpdateModelValue = (value: string) => {
  isShown.value = true
  inputValue.value = value
  if (value === '') {
    modelValue.value = undefined
    searchValue.value = undefined
  } else {
    searchValue.value = value.trim()
    if (props.fetchable) {
      emit('fetch:data', searchValue.value)
    }
  }
}

const setInputValue = (value?: string) => {
  inputValue.value =
    (value && props.data.find(item => item.value === value)?.label) || ''
}

const { pause, resume } = watchPausable(modelValue, setInputValue)

whenever(
  () => props.data.length === 0,
  () => {
    pause()
  },
  { immediate: true },
)

whenever(
  () => props.data.length > 0,
  () => {
    setInputValue(modelValue.value)
    resume()
  },
  { immediate: true },
)

watch(isShown, value => {
  if (value || searchValue.value === undefined) return
  if (searchedItem.value?.value !== modelValue.value) {
    setInputValue(modelValue.value)
  } else if (!modelValue.value) {
    inputValue.value = ''
  }
  searchValue.value = undefined
})
</script>

<style>
.ui-input-autocomplete {
  &.ui-input-text--xxs .ui-input-text__input--trailing {
    @apply pr-4;
  }

  &.ui-input-text--xs .ui-input-text__input--trailing {
    @apply pr-5;
  }

  &.ui-input-text--sm .ui-input-text__input--trailing {
    @apply pr-6;
  }

  &.ui-input-text--default .ui-input-text__input--trailing {
    @apply pr-7;
  }

  &.ui-input-text--lg .ui-input-text__input--trailing {
    @apply pr-7;
  }

  &.ui-input-text--xl .ui-input-text__input--trailing {
    @apply pr-7;
  }

  &.ui-input-text--xxs .ui-input-text-icon {
    @apply w-4;

    svg {
      @apply size-2.5;
    }
  }

  &.ui-input-text--xs .ui-input-text-icon {
    @apply w-5;

    svg {
      @apply size-3;
    }
  }

  &.ui-input-text--sm .ui-input-text-icon {
    @apply w-6;

    svg {
      @apply size-4;
    }
  }

  &.ui-input-text--default .ui-input-text-icon,
  &.ui-input-text--lg .ui-input-text-icon,
  &.ui-input-text--xl .ui-input-text-icon {
    @apply w-7;

    svg {
      @apply size-4;
    }
  }

  &__toggler {
    @apply cursor-pointer;
  }
}

.ui-input-autocomplete-popper {
  .v-popper__arrow-container {
    @apply hidden;
  }

  &__no-result {
    @apply py-2 px-3;
    @apply text-sm;
    @apply text-common-text;
  }

  &__create-button {
    @apply py-2 px-3;
  }
}
</style>
