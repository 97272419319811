<template>
  <Dropdown
    v-bind="{ container, placement }"
    ref="containerRef"
    :shown="openFlag"
    :disabled="dropdownDisabled"
    auto-boundary-max-size
    strategy="fixed"
    :triggers="[]"
    :auto-hide="false"
  >
    <UILabeledField
      v-bind="{
        ...$attrs,
        disabled,
        disableRecoveryValue,
        error,
        readonly,
        trailing,
      }"
      ref="inputRef"
      type="date"
      :model-value="currentValue"
      :class="openFlag ? 'datepicker--active' : null"
      @update:model-value="handleUpdate"
      @focus="handleFocus"
      @blur="handleBlur"
      @keydown="handleKeyDown"
      @click:trailing="handleClickTrailing"
    />
    <template #popper>
      <div ref="popperRef" class="datepicker">
        <div class="datepicker__list">
          <div class="datepicker__list-item" @click="handleSetDate('today')">
            Today
          </div>
          <div class="datepicker__list-item" @click="handleSetDate('lastweek')">
            Last week
          </div>
          <div
            class="datepicker__list-item"
            @click="handleSetDate('lastmonth')"
          >
            Last month
          </div>
          <div class="datepicker__list-item" @click="handleSetDate('lastyear')">
            Last year
          </div>
        </div>
        <div class="datepicker__wrapper">
          <div class="datepicker__header">
            <div class="space-x-1">
              <span
                :class="`datepicker__header__month${
                  isMonthSelect ? ' datepicker__header__month--active' : ''
                }`"
                @click="handleIsMonthSelect()"
                >{{ displayedMonth.toFormat('MMMM') }}</span
              >
              <span
                :class="`datepicker__header__year${
                  isYearSelect ? ' datepicker__header__year--active' : ''
                }`"
                @click="handleIsYearSelect()"
                >{{ displayedMonth.year }}</span
              >
            </div>
            <button
              v-if="isYearSelect || isMonthSelect"
              class="datepicker__action datepicker__action--close"
              type="button"
              @click="handleClosePanels"
            >
              <XMarkIcon aria-hidden="true" class="h-5 w-5" />
            </button>
            <div v-else class="datepicker__actions">
              <button
                class="datepicker__action datepicker__action--arrow"
                data-refid="date-picker__prev-month"
                type="button"
                @click="handleClickPrev"
              >
                <ChevronLeftIcon aria-hidden="true" class="h-5 w-5" />
              </button>
              <button
                class="datepicker__action datepicker__action--arrow"
                data-refid="date-picker__next-month"
                type="button"
                @click="handleClickNext"
              >
                <ChevronRightIcon aria-hidden="true" class="h-5 w-5" />
              </button>
            </div>
          </div>
          <div
            v-if="isYearSelect"
            class="datepicker__container datepicker__container--years"
          >
            <div
              v-for="item in noOfYears"
              :key="item"
              :class="{
                datepicker__year: true,
                'datepicker__year--current':
                  item === stringToDateTime(currentValue)?.year,
                'datepicker__year--selected': item === displayedMonth.year,
              }"
              @click="handleSetYear(item)"
            >
              {{ item }}
            </div>
          </div>
          <div
            v-else-if="isMonthSelect"
            class="datepicker__container datepicker__container--monthes"
          >
            <div
              v-for="(item, index) in MONTH_NAMES"
              :key="item"
              :class="{
                datepicker__month: true,
                'datepicker__month--current':
                  index + 1 === stringToDateTime(currentValue)?.month,
                'datepicker__month--selected':
                  index + 1 === displayedMonth.month,
              }"
              @click="handleSetMonth(index + 1)"
            >
              {{ item }}
            </div>
          </div>
          <template v-else>
            <div class="datepicker__week">
              <div v-for="(day, index) in DAYS" :key="index" class="flex-1">
                {{ day }}
              </div>
            </div>

            <div class="datepicker__container">
              <div v-for="blankday in blankdays" :key="blankday" />
              <div
                v-for="(day, dateIndex) in noOfDays"
                :key="dateIndex"
                :class="{
                  datepicker__item: true,
                  'datepicker__item--today': isToday(day) && !isSelected(day),
                  'datepicker__item--selected': isSelected(day),
                }"
                @click="(handleSelectDate(day), (openFlag = false))"
              >
                {{ day }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import { computed, ref, useTemplateRef, watch } from 'vue'
import { Dropdown, Placement } from 'floating-vue'
import { MaybeElement, onClickOutside, watchOnce } from '@vueuse/core'
import { DateTime } from 'luxon'

import { DAYS, MIN_DATE_YEAR, MONTH_NAMES } from '@/const/common'

import {
  isDate,
  dateTimeToString,
  getCurrentDate,
  getLastMonth,
  getLastWeek,
  getLastYear,
  stringToDateTime,
} from '@/helpers/dates'

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/vue/20/solid'
import { CalendarIcon } from '@heroicons/vue/24/outline'

import UILabeledField from '../DataField/LabeledField.vue'

type Props = {
  placement?: Placement
  error?: string
  inlinePopover?: boolean
  lazyFocus?: boolean
  disableRecoveryValue?: boolean
  container?: string

  disabled?: boolean
  readonly?: boolean
}

type Emits = {
  'update:late': [data?: string | null]
  'click:tab': [data: KeyboardEvent]
  'click:enter': [data: KeyboardEvent]
  'click:escape': [data: KeyboardEvent]
  ready: [data: HTMLElement]
}

const { placement = 'bottom-end', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string | null>({ default: getCurrentDate({}) })

const containerRef = useTemplateRef<MaybeElement>('containerRef')
const inputRef = useTemplateRef('inputRef')
const popperRef = useTemplateRef('popperRef')

defineExpose({
  blur() {
    inputRef.value?.blur()
  },
  focus() {
    inputRef.value?.focus()
  },
  select() {
    inputRef.value?.select()
  },
})

const currentValue = ref(modelValue.value)

const openFlag = ref(false)

const displayedMonth = ref(getCurrentDate().set({ day: 1 }))

const isYearSelect = ref(false)
const isMonthSelect = ref(false)

watch(
  modelValue,
  value => {
    if (!value || !isDate(value)) return
    const date = stringToDateTime(value)
    if (!date?.isValid) return
    displayedMonth.value = date
    currentValue.value = value
  },
  { immediate: true },
)

const dropdownDisabled = computed(() => props.disabled || props.readonly)

const trailing = computed(() =>
  dropdownDisabled.value ? undefined : { icon: CalendarIcon },
)

const currentValueAsDateTime = computed(() =>
  stringToDateTime(currentValue.value),
)

const previousValueAsDateTime = computed(() =>
  stringToDateTime(modelValue.value),
)

const noOfDays = computed<number[]>(() => {
  return [...Array(displayedMonth.value.daysInMonth).keys()].map(x => x + 1)
})
const blankdays = computed<number[]>(() => {
  const displayedMonthValue = displayedMonth.value.set({ day: 1 })
  return [...Array(displayedMonthValue.weekday).keys()].map(x => x + 1)
})
const noOfYears = Array(16)
  .fill('')
  .map((_, index) => getCurrentDate().year + index - 14)

const isToday = (day: number) => {
  return getCurrentDate().hasSame(displayedMonth.value.set({ day }), 'day')
}

const isSelected = (day: number) => {
  return stringToDateTime(currentValue.value)?.hasSame(
    displayedMonth.value.set({ day }),
    'day',
  )
}

const handleSelectDate = (day: number, updateLate = true) => {
  const date = displayedMonth.value.set({ day })
  const result = dateTimeToString(date, { iso: true })
  handleUpdate(result)
  if (!updateLate) return
  emit('update:late', result)
}

const nextMonth = () => {
  displayedMonth.value = displayedMonth.value.plus({ months: 1 })
}

const prevMonth = () => {
  displayedMonth.value = displayedMonth.value.plus({ months: -1 })
}

const nextYear = () => {
  displayedMonth.value = displayedMonth.value.plus({ years: 1 })
}

const prevYear = () => {
  displayedMonth.value = displayedMonth.value.plus({ years: -1 })
}

const validateDate = (value?: DateTime) => {
  if (!value) return value
  if (value.year < MIN_DATE_YEAR) {
    value = value.set({ year: MIN_DATE_YEAR })
  }
  return value
}

const handleUpdate = (value: any) => {
  const date = stringToDateTime(value)
  if (
    previousValueAsDateTime.value &&
    date?.equals(previousValueAsDateTime.value)
  )
    return
  const result = validateDate(date)
  currentValue.value = dateTimeToString(result, { iso: true })
  modelValue.value = currentValue.value
}

const handleBlur = () => {
  if (
    previousValueAsDateTime.value &&
    currentValueAsDateTime.value?.equals(previousValueAsDateTime.value)
  )
    return
  emit('update:late', currentValue.value)
}

const handleFocus = () => {
  if (props.lazyFocus) return
  openFlag.value = true
}

const handleClickPrev = () => {
  if ((event as KeyboardEvent).shiftKey) {
    prevYear()
  } else {
    prevMonth()
  }
}

const handleClickNext = () => {
  if ((event as KeyboardEvent).shiftKey) {
    nextYear()
  } else {
    nextMonth()
  }
}

onClickOutside(containerRef, event => {
  if (popperRef.value && event.composedPath().includes(popperRef.value)) {
    return
  }
  openFlag.value = false
})

const arrowNavigation = (key: string, shift = false) => {
  switch (key) {
    case 'ArrowUp':
      if (shift) {
        nextYear()
      } else if (displayedMonth.value.day - 7 > 0) {
        displayedMonth.value = displayedMonth.value.minus({ days: 7 })
      }
      break
    case 'ArrowDown':
      if (shift) {
        prevYear()
      } else if (displayedMonth.value.day + 7 <= noOfDays.value.length) {
        displayedMonth.value = displayedMonth.value.plus({ days: 7 })
      }
      break
    case 'ArrowRight':
      if (shift) {
        nextMonth()
      } else if (displayedMonth.value.day + 1 <= noOfDays.value.length) {
        displayedMonth.value = displayedMonth.value.plus({ days: 1 })
      }
      break
    case 'ArrowLeft':
      if (shift) {
        prevMonth()
      } else if (displayedMonth.value.day - 1 > 0) {
        displayedMonth.value = displayedMonth.value.minus({ days: 1 })
      }
      break
  }
  handleSelectDate(displayedMonth.value.day, false)
}

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Tab') {
    openFlag.value = false
    emit('click:tab', e)
    return
  }
  if (e.key === 'Escape') {
    if (openFlag.value) {
      openFlag.value = false
    } else {
      emit('click:escape', e)
    }
    return
  }
  if (e.key === 'Enter') {
    e.preventDefault()
    handleBlur()
    openFlag.value = false
    emit('click:enter', e)
  }
  if (e.key === 'ArrowDown' && !openFlag.value) {
    openFlag.value = true
    return
  }
  if (!openFlag.value) return
  if (e.key.startsWith('Arrow')) {
    e.preventDefault()
    arrowNavigation(e.key, e.shiftKey)
    return
  }
  if (!e.shiftKey) {
    openFlag.value = false
  }
}

const handleClickTrailing = () => {
  openFlag.value = true
  inputRef.value?.focus()
}

const handleSetDate = (
  period: 'today' | 'lastweek' | 'lastmonth' | 'lastyear',
) => {
  let date = getCurrentDate()
  switch (period) {
    case 'lastweek':
      date = getLastWeek(date)
      break
    case 'lastmonth':
      date = getLastMonth(date)
      break
    case 'lastyear':
      date = getLastYear(date)
      break
  }
  const result = dateTimeToString(date, { iso: true })
  handleUpdate(result)
  emit('update:late', result)
  openFlag.value = false
}

const handleIsMonthSelect = () => {
  isMonthSelect.value = !isMonthSelect.value
  isYearSelect.value = false
}

const handleIsYearSelect = () => {
  isYearSelect.value = !isYearSelect.value
  isMonthSelect.value = false
}

const handleClosePanels = () => {
  isYearSelect.value = false
  isMonthSelect.value = false
}

const handleSetYear = (item: number) => {
  displayedMonth.value = displayedMonth.value.set({ year: item })
  handleClosePanels()
}

const handleSetMonth = (item: number) => {
  displayedMonth.value = displayedMonth.value.set({ month: item })
  handleClosePanels()
}

watchOnce(inputRef, el => {
  const input = el
    ?.getContainer()
    ?.querySelector('input.date-input__item') as HTMLElement
  if (!input) return
  emit('ready', input)
})
</script>

<script lang="ts">
export default {
  name: 'UIDatePickerField',
  inheritAttrs: false,
}
</script>

<style>
.datepicker {
  @apply flex;
  @apply bg-white dark:bg-gray-800;
}

.datepicker__list {
  @apply p-2;
  @apply border-r border-gray-100 dark:border-gray-700;
  @apply text-xs font-medium;
}

.datepicker__list-item {
  @apply p-2;
  @apply text-gray-500 hover:text-indigo-600;
  @apply dark:text-gray-400 dark:hover:text-indigo-400;
  @apply cursor-pointer;
}

.datepicker__wrapper {
  @apply w-72;
  @apply p-4;
}

.datepicker__header {
  @apply flex justify-between items-center;
  @apply mb-4;
}

.datepicker__header__month,
.datepicker__header__year {
  @apply font-normal text-gray-600 hover:text-gray-800;
  @apply dark:text-gray-400 dark:hover:text-gray-200;
  @apply cursor-pointer;
}

.datepicker__header__month--active,
.datepicker__header__year--active {
  @apply font-bold text-gray-800 dark:text-gray-200;
}

.datepicker__actions {
  @apply flex items-center space-x-1;
}

.datepicker__action {
  @apply h-5;
  @apply flex items-center;
  @apply px-2;
  @apply rounded-xl;
  @apply text-gray-500 hover:text-gray-700 focus:text-indigo-700;
  @apply dark:text-gray-400 dark:hover:text-gray-200 dark:focus:text-indigo-400;
  @apply bg-gray-50 hover:bg-gray-200 focus:bg-indigo-100;
  @apply dark:bg-gray-750 dark:hover:bg-gray-700 dark:focus:bg-indigo-500;
  @apply font-bold text-xs uppercase;
  @apply cursor-pointer;
}

.datepicker__action--arrow,
.datepicker__action--close {
  @apply w-5;
  @apply justify-center;
  @apply px-0;
}

.datepicker__action--arrow {
  @apply rounded-full;
}

.datepicker__action--close {
  @apply rounded-sm;
}

.datepicker__week {
  @apply h-5;
  @apply flex items-center space-x-2;
  @apply -mx-4 mb-2 px-4;
  @apply bg-gray-50 text-gray-400;
  @apply dark:bg-gray-750 dark:text-gray-300;
  @apply text-xs text-center;
  @apply uppercase;
}

.datepicker__container {
  @apply grid grid-cols-7 gap-2;
  @apply -mx-4 px-4;
  @apply text-center;
}

.datepicker__container--years {
  @apply grid-cols-4;
}

.datepicker__container--monthes {
  @apply grid-cols-3;
}

.datepicker__item,
.datepicker__year,
.datepicker__month {
  @apply flex items-center justify-center;
  @apply mx-auto;
  @apply text-gray-600 dark:text-gray-400;
  @apply border border-transparent;
  @apply rounded-full;
  @apply leading-loose;
  @apply cursor-pointer;
}

.datepicker__item {
  @apply w-5 h-5;
  @apply text-xs;
}

.datepicker__year,
.datepicker__month {
  @apply h-6;
  @apply my-0.5 px-1;
  @apply rounded;
  @apply text-sm;
}

.datepicker__item:not(.datepicker__item--selected),
.datepicker__year:not(.datepicker__year--selected),
.datepicker__month:not(.datepicker__month--selected) {
  @apply hover:text-gray-700 hover:bg-gray-100;
  @apply dark:hover:text-gray-300 dark:hover:bg-gray-700;
}

.datepicker__item--today:not(.datepicker__item--selected),
.datepicker__year--current:not(.datepicker__year--selected),
.datepicker__month--current:not(.datepicker__month--selected) {
  @apply text-indigo-700 border-indigo-500;
}

.datepicker__item--selected,
.datepicker__year--selected,
.datepicker__month--selected {
  @apply bg-indigo-700 text-white;
  @apply dark:bg-indigo-500 dark:text-gray-50;
}
</style>
