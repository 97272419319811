<template>
  <label class="ui-input-text-label">
    <span>{{ label }}</span>
    <span v-if="error" class="ui-input-text-label__error" :class="errorClasses">
      {{ error }}
    </span>
    <QuestionMarkCircleIcon
      v-if="description"
      v-tooltip.top="description"
      aria-hidden="true"
      class="ui-input-text-label__description"
    />
  </label>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'

type Props = {
  label?: string
  error?: string
  description?: string

  silent?: boolean
}

const props = defineProps<Props>()

const errorClasses = computed(() => ({
  'ui-input-text-label__error--silent': props.silent,
}))
</script>

<style>
.ui-input-text-label {
  @apply flex items-baseline;
  @apply gap-1 mb-1;
  @apply text-sm font-medium leading-tight;
  @apply text-[var(--label-text)];

  &__error {
    @apply text-xs leading-tight;
    @apply text-[var(--error-text)];

    &--silent {
      @apply text-[var(--silent-text)];
    }
  }

  &__description {
    @apply size-4;
    @apply translate-y-0.5;
    @apply shrink-0;
    @apply ml-auto -my-0.5;
    @apply text-[var(--question-color)];
    @apply cursor-help;
  }
}
</style>
