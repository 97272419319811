<template>
  <UIInputText v-model="modelValue" :type :disabled :readonly>
    <template v-if="!readonly" #trailing>
      <button type="button" :disabled tabindex="-1" @click="handleToggle">
        <component :is="icon" aria-hidden="true" />
      </button>
    </template>
  </UIInputText>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import UIInputText from './Text/Text.vue'

type Props = {
  disabled?: boolean
  readonly?: boolean
}

defineProps<Props>()

const modelValue = defineModel<string>({ required: true })

const masked = ref(true)

const type = computed(() => (masked.value ? 'password' : 'text'))

const icon = computed(() => (masked.value ? EyeIcon : EyeSlashIcon))

const handleToggle = () => {
  masked.value = !masked.value
}
</script>
