<template>
  <div class="ui-input-text" :class="mainClasses">
    <TextLabel v-if="label" :for="id" :label :error :description :silent />
    <div class="ui-input-text__area">
      <component
        :is="inputComponent || 'input'"
        :value="modelValue"
        ref="inputRef"
        :data-refid
        :type
        :id
        :placeholder
        :disabled
        :readonly
        :autocomplete
        class="ui-input-text__input"
        :class="inputClasses"
        :style="inputStyle"
        v-bind="componentProps"
        @keydown.enter="handleClickEnter"
        @keydown.tab="handleClickTab"
        @keydown.escape="handleClickEscape"
        @blur="handleBlur"
        @focus="handleFocus"
        @input="modelValue = $event.target.value"
      />
      <span v-if="prefix" class="ui-input-text__prefix">{{ prefix }}</span>
      <TextIcon v-if="hasLeading" leading><slot name="leading" /></TextIcon>
      <TextIcon
        v-if="isExclamationVisible"
        trailing
        class="ui-input-text__exclamation"
      >
        <ExclamationCircleIcon aria-hidden="true" v-tooltip="error" />
      </TextIcon>
      <TextIcon v-else-if="hasTrailing" trailing>
        <slot name="trailing" />
      </TextIcon>
    </div>
    <slot name="after" />
    <div
      v-if="responsive"
      ref="sizerRef"
      class="ui-input-text__sizer"
      v-text="sizerValue"
    />
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  getCurrentInstance,
  useSlots,
  useTemplateRef,
  watch,
} from 'vue'
import { useElementBounding } from '@vueuse/core'

import { InputSize } from '../utils/types'
import { InputTextEmits, InputTextType } from './utils/types'

import TextIcon from './TextIcon.vue'
import TextLabel from './TextLabel.vue'

import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'

type Props = {
  label?: string
  placeholder?: string
  dataRefid?: string

  type?: InputTextType
  size?: InputSize
  responsive?: boolean

  prefix?: string

  disabled?: boolean
  readonly?: boolean

  autocomplete?: string
  error?: string
  description?: string
  silent?: boolean

  inputComponent?: any
  componentProps?: any
}

type Emits = InputTextEmits

const { type = 'text', size = 'default', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string>({ default: '' })

const inputRef = useTemplateRef<HTMLInputElement>('inputRef')
const sizerRef = useTemplateRef('sizerRef')

const slots = useSlots()

const { width } = useElementBounding(sizerRef)

const id = computed(() => `input-${getCurrentInstance()?.uid.toString()}`)

const hasTrailing = computed(
  () => isExclamationVisible.value || !!slots.trailing?.(),
)
const hasLeading = computed(() => !!slots.leading?.())

const isExclamationVisible = computed(() => props.error && !props.silent)

const mainClasses = computed(() => ({
  [`ui-input-text--${size}`]: true,
  'ui-input-text--error': isExclamationVisible.value,
  'ui-input-text--responsive': props.responsive,
}))

const inputClasses = computed(() => ({
  'ui-input-text__input--prefix': !hasLeading.value && props.prefix,
  'ui-input-text__input--leading': hasLeading.value && !props.prefix,
  'ui-input-text__input--leading-prefix': hasLeading.value && props.prefix,
  'ui-input-text__input--trailing': hasTrailing.value,
}))

const inputStyle = computed(() =>
  props.responsive
    ? {
        width: `${width.value}px`,
      }
    : undefined,
)

const sizerValue = computed(() => {
  const tagName = inputRef.value?.tagName
  switch (tagName) {
    case 'SELECT':
      return inputRef.value?.querySelector(
        `option[value="${modelValue.value}"]`,
      )?.textContent
    default:
      return modelValue.value
  }
})

const handleReady = (el: HTMLInputElement | null) => {
  if (!el) return
  emit('ready', el)
}

const handleClickEnter = (event: KeyboardEvent) => {
  emit('click:enter', event)
}

const handleClickTab = (event: KeyboardEvent) => {
  emit('click:tab', event)
}

const handleClickEscape = (event: KeyboardEvent) => {
  emit('click:escape', event)
}

const handleBlur = (event: FocusEvent) => {
  emit('blur', event.target as HTMLInputElement)
}

const handleFocus = (event: FocusEvent) => {
  emit('focus', event.target as HTMLInputElement)
}

watch(inputRef, handleReady, { immediate: true })
</script>

<style>
@import './assets/styles/colors.css';

.ui-input-text {
  &__area {
    @apply grid grid-cols-1;

    &:has([readonly], [disabled]) {
      @apply pointer-events-none;
    }
  }

  &--responsive {
    @apply w-fit;
  }

  &--responsive &__area {
    @apply flex;
  }

  &__input {
    @apply w-full;
    @apply block;
    @apply col-start-1 row-start-1;

    &:is(input, textarea, select) {
      @apply bg-[var(--back-color)];
      @apply text-[var(--text-color)];
      @apply placeholder:text-[var(--placeholder-text)];
      @apply border-none !ring-0;
      @apply outline outline-1 -outline-offset-1 outline-[var(--border-color)];
    }

    &:focus,
    .ui-input-text--active & {
      &:is(input, textarea, select) {
        @apply outline-2 -outline-offset-2 outline-[var(--focus-border)];
      }
    }

    &:is([disabled]) {
      @apply text-[var(--disabled-color)];
    }

    .ui-input-text--error & {
      &:is(input, textarea, select) {
        @apply outline-[var(--error-border)];
      }
    }

    .ui-input-text--xxs & {
      @apply p-[.25rem]; /* 24px */
      @apply text-xs;
      @apply rounded;
    }

    .ui-input-text--xs & {
      @apply p-[.438rem]; /* 30px */
      @apply text-xs;
      @apply rounded;
    }

    .ui-input-text--sm & {
      @apply p-[.563rem]; /* 34px */
      @apply text-xs;
      @apply rounded-md;
    }

    .ui-input-text--default & {
      @apply py-[.563rem] px-3; /* 38px */
      @apply text-sm;
      @apply rounded-md;
    }

    .ui-input-text--lg & {
      @apply py-[.563rem] px-3.5; /* 42px */
      @apply text-base;
      @apply rounded-md;
    }

    .ui-input-text--xl & {
      @apply py-[.813rem] px-4; /* 50px */
      @apply text-base;
      @apply rounded-md;
    }

    .ui-input-text--xxs &--leading {
      @apply pl-7;
    }

    .ui-input-text--xxs &--trailing {
      @apply pr-6;
    }

    .ui-input-text--xs &--leading {
      @apply pl-7;
    }

    .ui-input-text--xs &--trailing {
      @apply pr-7;
    }

    .ui-input-text--sm &--leading {
      @apply pl-8;
    }

    .ui-input-text--sm &--trailing {
      @apply pr-8;
    }

    .ui-input-text--default &--leading,
    .ui-input-text--lg &--leading,
    .ui-input-text--xl &--leading {
      @apply pl-10;
    }

    .ui-input-text--default &--trailing,
    .ui-input-text--lg &--trailing,
    .ui-input-text--xl &--trailing {
      @apply pr-10;
    }

    .ui-input-text--xxs &--prefix {
      @apply pl-[.75rem];
    }

    .ui-input-text--xs &--prefix,
    .ui-input-text--sm &--prefix {
      @apply pl-4;
    }

    .ui-input-text--default &--prefix {
      @apply pl-5;
    }

    .ui-input-text--lg &--prefix {
      @apply pl-6;
    }

    .ui-input-text--xl &--prefix {
      @apply pl-7;
    }

    .ui-input-text--xxs &--leading-prefix,
    .ui-input-text--xs &--leading-prefix {
      @apply pl-9;
    }

    .ui-input-text--sm &--leading-prefix {
      @apply pl-10;
    }

    .ui-input-text--default &--leading-prefix,
    .ui-input-text--lg &--leading-prefix,
    .ui-input-text--xl &--leading-prefix {
      @apply pl-11;
    }
  }

  &__prefix {
    @apply flex items-center justify-end;
    @apply col-start-1 row-start-1;
    @apply justify-self-start;
    @apply text-inherit text-light-text;

    .ui-input-text--xxs & {
      @apply w-[.75rem];
    }

    .ui-input-text--xs & {
      @apply w-4;
    }

    .ui-input-text--sm & {
      @apply w-4;
    }

    .ui-input-text--default & {
      @apply w-5;
    }

    .ui-input-text--lg & {
      @apply w-6;
    }

    .ui-input-text--xl & {
      @apply w-7;
    }

    .ui-input-text--xxs .ui-input-text__input--leading-prefix + &,
    .ui-input-text--xs .ui-input-text__input--leading-prefix + & {
      @apply w-9;
    }

    .ui-input-text--sm .ui-input-text__input--leading-prefix + & {
      @apply w-10;
    }

    .ui-input-text--default .ui-input-text__input--leading-prefix + &,
    .ui-input-text--lg .ui-input-text__input--leading-prefix + &,
    .ui-input-text--xl .ui-input-text__input--leading-prefix + & {
      @apply w-11;
    }
  }

  &__sizer {
    @apply absolute top-0 left-0;
    @apply invisible;
    @apply pointer-events-none;
    @apply -z-10;

    .ui-input-text--xxs & {
      @apply !text-xs;
    }

    .ui-input-text--xs & {
      @apply !text-xs;
    }

    .ui-input-text--sm & {
      @apply !text-xs;
    }

    .ui-input-text--default & {
      @apply !text-sm;
    }

    .ui-input-text--lg &,
    .ui-input-text--xl & {
      @apply text-base;
    }
  }

  &__exclamation {
    @apply text-[var(--error-text)];
  }
}
</style>
