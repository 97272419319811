<template>
  <DropdownList
    v-model="isShown"
    :data
    :disabled="isDropdownDisabled"
    :current-value="modelValue"
    :native="isNativeSelect"
    :popper-class
    :size
    @click:item="handleClickItem"
    @hide="handleHide"
  >
    <template #default="{ show, toggle, icon }">
      <UIInputText
        v-model="value"
        :disabled
        :readonly
        :size
        :placeholder
        :input-component
        :responsive="unstyled"
        class="ui-input-select"
        :class="inputClasses"
        @click="show"
        @focus="handleFocus"
        @blur="handleBlur"
        @keydown="handleKeyDown"
        @ready="handleReady"
      >
        <template v-if="$slots.trailing" #trailing>
          <slot name="trailing" />
        </template>
        <template v-else-if="isIconVisible" #trailing>
          <div class="ui-input-select__toggler" @click.stop="toggle">
            <component :is="icon" aria-hidden="true" />
          </div>
        </template>
      </UIInputText>
    </template>
  </DropdownList>
</template>

<script setup lang="ts">
import { computed, h, ref } from 'vue'
import { Capacitor } from '@capacitor/core'

import { DropdownListData, InputSize } from '@types'

import { useLocale } from '@/plugins/i18n'
import { useResponsive } from '@/plugins/responsiveUI'

import DropdownList from '../Dropdown/DropdownList.vue'
import UIInputText from './Text/Text.vue'

type Props = {
  data: DropdownListData[]

  size?: InputSize

  disabled?: boolean
  readonly?: boolean
  unstyled?: boolean

  placeholder?: string
}

type Emits = {
  focus: [event: HTMLInputElement]
  blur: [event: HTMLInputElement]
  ready: [event: HTMLInputElement]
  select: [value: string]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string | undefined>({ required: true })

const exposeObj = {
  open: () => {
    isShown.value = true
    return exposeObj
  },
  close: () => {
    isShown.value = false
    return exposeObj
  },
  focus: () => {
    inputRef.value?.focus()
    return exposeObj
  },
}

defineExpose(exposeObj)

const { t } = useLocale('components.UI.Input')

const { isMobile } = useResponsive()

const inputRef = ref<HTMLInputElement>()
const isShown = ref(false)

const value = computed({
  get() {
    return isNativeSelect.value
      ? modelValue.value
      : props.data.find(item => item.value === modelValue.value)?.label
  },
  set(value) {
    modelValue.value = value
  },
})

const isNativeSelect = computed(
  () => Capacitor.isNativePlatform() || isMobile.value,
)
const isDropdownDisabled = computed(
  () => props.disabled || isNativeSelect.value,
)

const readonly = computed(() => props.readonly || !isNativeSelect.value)

const inputComponent = computed(() => {
  return isNativeSelect.value
    ? h(
        'select',
        {},
        props.data.map(item =>
          h('option', { key: item.value, value: item.value }, item.label),
        ),
      )
    : undefined
})

const isIconVisible = computed(() => !props.readonly && !isNativeSelect.value)

const inputClasses = computed(() => ({
  'ui-input-text--active': !isNativeSelect.value && isShown.value,
  'ui-input-select--unstyled': props.unstyled,
}))

const popperClass = computed(() => ({
  'ui-input-select-popper': true,
  'ui-input-select-popper--unstyled': props.unstyled,
}))

const placeholder = computed(() => props.placeholder || t('Select an option'))

const handleClickItem = (value: string) => {
  modelValue.value = value
  emit('select', value)
}

const handleFocus = () => {
  inputRef.value && emit('focus', inputRef.value)
}

const handleBlur = () => {
  inputRef.value && emit('blur', inputRef.value)
}

const handleReady = (e: HTMLInputElement) => {
  inputRef.value = e
  inputRef.value && emit('ready', inputRef.value)
}

const handleKeyDown = (e: KeyboardEvent) => {
  if (isShown.value) return
  switch (e.key) {
    case 'ArrowDown':
    case 'ArrowUp':
      isShown.value = true
      break
  }
}

const handleHide = () => {
  inputRef.value?.focus()
}
</script>

<style>
.ui-input-select {
  &--unstyled {
    @apply text-inherit text-[length:inherit];
    @apply leading-[inherit];
    text-transform: inherit;

    .ui-input-text__input {
      @apply !p-0;
      @apply bg-transparent;
      @apply rounded-sm;
      @apply !outline-none;
      @apply cursor-pointer;
    }

    .ui-input-text__input,
    .ui-input-text__sizer {
      @apply !text-inherit !text-[length:inherit];
      @apply !leading-[inherit];
      text-transform: inherit;
    }

    .ui-input-text-icon {
      @apply !w-auto;
      @apply pl-0.5;
    }

    .ui-input-text__sizer {
      @apply pr-6 sm:pr-0;
    }
  }

  .ui-input-text__area {
    @apply pointer-events-auto;
  }

  &.ui-input-text--xxs .ui-input-text__input--trailing {
    @apply pr-4;
  }

  &.ui-input-text--xs .ui-input-text__input--trailing {
    @apply pr-5;
  }

  &.ui-input-text--sm .ui-input-text__input--trailing {
    @apply pr-6;
  }

  &.ui-input-text--default .ui-input-text__input--trailing {
    @apply pr-7;
  }

  &.ui-input-text--lg .ui-input-text__input--trailing {
    @apply pr-7;
  }

  &.ui-input-text--xl .ui-input-text__input--trailing {
    @apply pr-7;
  }

  &.ui-input-text--xxs .ui-input-text-icon {
    @apply w-4;

    svg {
      @apply size-2.5;
    }
  }

  &.ui-input-text--xs .ui-input-text-icon {
    @apply w-5;

    svg {
      @apply size-3;
    }
  }

  &.ui-input-text--sm .ui-input-text-icon {
    @apply w-6;

    svg {
      @apply size-4;
    }
  }

  &.ui-input-text--default .ui-input-text-icon,
  &.ui-input-text--lg .ui-input-text-icon,
  &.ui-input-text--xl .ui-input-text-icon {
    @apply w-7;

    svg {
      @apply size-4;
    }
  }

  &__toggler {
    @apply cursor-pointer;
  }
}

.ui-input-select-popper {
  &--unstyled {
    .v-popper__inner {
      @apply min-w-44;
    }
    .ui-dropdown-list__container {
      @apply py-1;
    }
  }

  &:not(&--unstyled) {
    .v-popper__arrow-container {
      @apply hidden;
    }
  }
}
</style>
