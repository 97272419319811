<template>
  <div class="ui-input-text-icon" :class="mainClasses">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
type Props = {
  leading?: boolean
  trailing?: boolean
}

const props = defineProps<Props>()

const mainClasses = computed(() => ({
  'ui-input-text-icon--leading': props.leading,
  'ui-input-text-icon--trailing': props.trailing,
}))
</script>

<style>
.ui-input-text-icon {
  @apply flex justify-center self-center;
  @apply col-start-1 row-start-1;
  @apply text-gray-400;

  &--trailing {
    @apply justify-self-end;
  }

  .ui-input-text--xxs & {
    @apply w-6;

    svg {
      @apply size-3;
    }
  }

  .ui-input-text--xs & {
    @apply w-7;

    svg {
      @apply size-4;
    }
  }

  .ui-input-text--sm & {
    @apply w-8;

    svg {
      @apply size-4;
    }
  }

  .ui-input-text--default & {
    @apply w-10;

    svg {
      @apply size-5;
    }
  }

  .ui-input-text--lg & {
    @apply w-10;

    svg {
      @apply size-5;
    }
  }

  .ui-input-text--xl & {
    @apply w-10;

    svg {
      @apply size-5;
    }
  }
}
</style>
