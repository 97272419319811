<template>
  <InputAutocomplete
    v-model="modelValue"
    :data
    :editable
    dynamic
    @click:new="handleClickNew"
  >
    <template v-if="loading" #trailing>
      <UIPreloader />
    </template>
    <template v-if="$slots.item || editable" #item="slotProps">
      <template v-if="editable">
        <DynamicListItem
          v-bind="slotProps"
          :lockedKey
          :removable
          @update="handleUpdate"
          @delete="handleDelete"
        >
          <slot name="item" v-bind="slotProps" />
        </DynamicListItem>
      </template>
      <slot v-else name="item" v-bind="slotProps" />
    </template>
  </InputAutocomplete>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { DropdownListData } from '@types'

import InputAutocomplete from '../Autocomplete.vue'
import UIPreloader from '../../Preloader/Preloader.vue'
import DynamicListItem from './DynamicListItem.vue'

type Props = {
  data: DropdownListData[]

  lockedKey?: string

  editable?: boolean
  removable?: boolean
}

type Emits = {
  'click:new': [data: string, callback: () => void]
  update: [data: string, value: string]
  delete: [data: string, callback: () => void]
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<string | undefined>({ required: true })

const loading = ref(false)

const handleClickNew = (data: string, callback: () => void) => {
  loading.value = true
  emit('click:new', data, () => {
    loading.value = false
    callback()
  })
}

const handleUpdate = (data: string, value: string) => {
  emit('update', data, value)
}

const handleDelete = (data: string, callback: () => void) => {
  emit('delete', data, callback)
}
</script>
