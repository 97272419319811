<template>
  <InputNumber
    v-model="modelValue"
    :handle-update
    sufix="%"
    format-style="percent"
  >
    <template v-if="$slots.trailing" #leading>
      <slot name="trailing" />
    </template>
    <template v-if="$slots.trailing" #trailing>
      <slot name="trailing" />
    </template>
  </InputNumber>
</template>

<script setup lang="ts">
import InputNumber from './Number.vue'

const modelValue = defineModel<number | null>({ default: null })

const handleUpdate = (value: number) => value / 100
</script>
