<template>
  <UIInputText v-model="modelValue">
    <template v-if="$slots.leading" #leading>
      <slot name="leading" />
    </template>
    <template #trailing>
      <button v-if="isButtonVisible" type="button" @click="handleclear">
        <XMarkIcon aria-hidden="true" />
      </button>
      <slot v-else-if="$slots.trailing" name="trailing" />
    </template>
    <template v-if="$slots.after" #after>
      <slot name="after" />
    </template>
  </UIInputText>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { XMarkIcon } from '@heroicons/vue/24/outline'

import UIInputText from './Text/Text.vue'

const modelValue = defineModel<string>({ required: true })

const isButtonVisible = computed(() => !!modelValue.value)

const handleclear = () => {
  modelValue.value = ''
}
</script>
