<template>
  <UIInputTextClearable
    :model-value="fileName"
    readonly
    class="ui-input-file"
    @update:model-value="handleUdpate"
  >
    <template #leading>
      <PaperClipIcon aria-hidden="true" />
    </template>
    <template v-if="isLoading" #trailing>
      <LoaderIcon aria-hidden="true" />
    </template>
    <template #after>
      <input
        ref="fileRef"
        type="file"
        :data-refid="dataRefid"
        :accept
        :disabled
        class="ui-input-file__input"
        @change="handleUploadFile"
      />
    </template>
  </UIInputTextClearable>
</template>

<script setup lang="ts">
import { ref, useTemplateRef } from 'vue'

import { PaperClipIcon } from '@heroicons/vue/24/outline'
import LoaderIcon from '../Preloader/PreloaderIcon.vue'
import UIInputTextClearable from './Clearable.vue'

interface HTMLInputFileEvent extends Event {
  target: HTMLInputElement & EventTarget
}

type Props = {
  dataRefid?: string
  accept?: string

  disabled?: boolean
}

defineProps<Props>()

const modelValue = defineModel<FormData>()

const fileRef = useTemplateRef('fileRef')

const fileName = ref('')
const isLoading = ref(false)

const handleUploadFile = (e: Event) => {
  const event = e as HTMLInputFileEvent
  if (!event.target?.files?.length) return

  isLoading.value = true

  const file = event.target.files?.[0]

  if (file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('filename', file.name)

    fileName.value = file.name
    modelValue.value = formData
  }

  isLoading.value = false
}

const handleUdpate = () => {
  fileRef.value && (fileRef.value.value = '')
  fileName.value = ''
  modelValue.value = undefined
}
</script>

<style>
.ui-input-file {
  @apply relative;

  .ui-input-text__area {
    @apply pointer-events-auto;
  }

  &__input {
    @apply absolute inset-0 z-0;
    @apply opacity-0;

    &:not([disabled]) {
      @apply cursor-pointer;
    }
  }

  .ui-input-text-icon--trailing {
    @apply z-[1];
  }
}
</style>
