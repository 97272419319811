<template>
  <div
    class="ui-dropdown-list-item ui-dropdown-list-item-edit"
    :class="itemClasses"
  >
    <template v-if="lockedMode">
      <slot>
        <span class="ui-dropdown-list-item-edit__label">
          {{ displayValue }}
        </span>
      </slot>
      <LockClosedIcon class="ui-dropdown-list-item-edit__lock" />
    </template>
    <template v-else-if="editMode">
      <UIInputText
        v-model="modelValue"
        size="xs"
        class="ui-dropdown-list-item-edit__input"
        @click.stop
        @click:enter="handleApply"
        @ready="(e: HTMLInputElement) => e.focus()"
      />
      <UIButtonIcon
        :leading="CheckIcon"
        :size
        variant="secondary"
        fill="outlined"
        @click.stop="handleApply"
      />
      <UIButtonIcon
        :leading="XMarkIcon"
        :size
        variant="danger"
        fill="outlined"
        @click.stop="handleCancel"
      />
    </template>
    <template v-else-if="confirmDeleteMode">
      <div class="ui-dropdown-list-item-edit__delete" @click.stop>
        <template v-if="deleting">
          {{ t('Deleting') }} "{{ displayValue }}"...
        </template>
        <template v-else>
          {{ t('Are you sure you want to delete the') }} "{{ displayValue }}"?
        </template>
      </div>
      <template v-if="!deleting">
        <UIButton
          :label="t('Yes')"
          variant="danger"
          :size
          @click.stop="handleConfirmDelete"
        />
        <UIButton
          :label="t('No')"
          variant="secondary"
          :size
          @click.stop="handleCancel"
        />
      </template>
    </template>
    <template v-else>
      <slot>
        <span class="ui-dropdown-list-item-edit__label">
          {{ displayValue }}
        </span>
      </slot>
      <UIButtonIcon
        :leading="PencilIcon"
        :size
        variant="secondary"
        fill="outlined"
        class="ui-dropdown-list-item-edit__action"
        @click.stop="handleEdit"
      />
      <UIButtonIcon
        v-if="removable"
        :leading="TrashIcon"
        :size
        variant="danger"
        fill="outlined"
        class="ui-dropdown-list-item-edit__action"
        @click.stop="handleDelete"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { DropdownListData } from '@types'

import { useLocale } from '@/plugins/i18n'
import { useHotKeys } from '@/plugins/hotKeys'

import {
  CheckIcon,
  LockClosedIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { UIButton, UIButtonIcon } from '@ui/buttons'
import UIInputText from '../Text/Text.vue'

const size = 'xxs'

type Props = {
  item: DropdownListData

  lockedKey?: string

  removable?: boolean

  isSelected?: boolean
  isActive?: boolean

  itemClasses?: string | Record<string, boolean>

  hide?: () => void
}

type Emits = {
  update: [data: string, value: string]
  delete: [data: string, callback: () => void]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useLocale('components.UI.Input')

const editMode = ref(false)
const confirmDeleteMode = ref(false)

const deleting = ref(false)

const modelValue = ref('')

const displayValue = computed(() => props.item.label)

const lockedMode = computed(
  () => props.lockedKey && props.item.meta?.[props.lockedKey],
)

const handleApply = () => {
  editMode.value = false
  emit('update', props.item.value, modelValue.value)
}

const handleCancel = () => {
  editMode.value = false
  confirmDeleteMode.value = false
}

const handleEdit = () => {
  editMode.value = true
}

const handleDelete = () => {
  confirmDeleteMode.value = true
}

const handleConfirmDelete = () => {
  deleting.value = true
  emit('delete', props.item.value, () => {
    deleting.value = false
  })
}

watch(editMode, value => {
  if (!value) return
  modelValue.value = displayValue.value
})

useHotKeys(
  'escape',
  () => {
    if (!editMode.value && !confirmDeleteMode.value) return
    editMode.value = false
    confirmDeleteMode.value = false
    return true
  },
  { priority: 110 },
)
</script>

<style>
.ui-dropdown-list-item-edit {
  &__lock {
    @apply size-4;
    @apply text-light-text;
  }

  &__label,
  &__input,
  &__delete {
    @apply flex-auto;
  }

  &__input {
    @apply -my-1.5 -ml-1;
  }

  &__delete {
    @apply text-xs;
    @apply text-common-text;
  }

  &__action {
    @apply hidden;
  }

  &:hover &__action {
    @apply block;
  }

  .ui-button-icon {
    @apply -my-0.5;
  }
}
</style>
