<template>
  <UIInputText
    :model-value="formattedValue"
    v-tooltip="tooltip"
    :prefix
    :placeholder
    :input-component
    :component-props
    @keydown="handleKeyDown"
    @update:model-value="handleUpdate"
    @ready="handleReady"
  >
    <template v-if="$slots.leading" #leading>
      <slot name="leading" />
    </template>
    <template v-if="$slots.trailing" #trailing>
      <slot name="trailing" />
    </template>
  </UIInputText>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { watchPausable } from '@vueuse/core'
import { component as inputComponent } from '@coders-tm/vue-number-format'

import { useLocale } from '@/plugins/i18n'

import UIInputText from './Text/Text.vue'

const PRECISION = 10

type Props = {
  placeholder?: string
  suffix?: string

  formatStyle?: 'decimal' | 'percent'

  hideSign?: boolean

  alwaysNegative?: boolean
  alwaysPositive?: boolean

  handleUpdate?: (value: number) => number
}

type Emits = {
  (e: 'ready', el: HTMLInputElement): void
}

const { suffix, formatStyle = 'decimal', ...props } = defineProps<Props>()
const emit = defineEmits<Emits>()

const modelValue = defineModel<number | null>({ default: null })

const { t } = useLocale('components.UI.Input')

const inputRef = ref<HTMLInputElement>()

const formattedValue = ref('')

const isTooltipShown = ref(false)

const tooltipContent = computed(() =>
  props.alwaysNegative ? t('Must be negative') : t('Must be positive'),
)

const tooltip = computed(() => ({
  content: tooltipContent.value,
  shown: isTooltipShown.value,
  triggers: ['manual'],
}))

const isNegative = computed(() => modelValue.value && modelValue.value < 0)
const isSignVisible = computed(() => isNegative.value && !props.hideSign)

const prefix = computed(() => (isSignVisible.value ? '-' : undefined))

const componentProps = computed(() => ({
  precision: PRECISION,
  nullValue: null,
  suffix,
  inputmode: 'number',
  modelValue: '',
}))

const placeholder = computed(() => props.placeholder ?? `0.00${suffix || ''}`)

const transformToModelValue = (value: string) => {
  return (
    Number(value.replace(/[^0-9.]/g, '')) *
    (isNegative.value || props.alwaysNegative ? -1 : 1)
  )
}

const transformToFormattedValue = (value: number) => {
  const result = Number(value)
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: PRECISION,
    style: formatStyle,
  }).format(Math.abs(result))
}

const handleUpdate = (value: string) => {
  formattedValue.value = value.replaceAll('-', '')
}

const updateModel = (value: string) => {
  modelWatchPause()
  if (!value) {
    modelValue.value = -0
  } else {
    const result = transformToModelValue(value)
    modelValue.value = props.handleUpdate ? props.handleUpdate(result) : result
  }
  nextTick(modelWatchResume)
}

const updateInput = (value: number | null) => {
  formatedWatchPause()
  if (value === null) {
    formattedValue.value = ''
  } else {
    formattedValue.value = transformToFormattedValue(value)
  }
  nextTick(formatedWatchResume)
}

const handleKeyDown = (event: KeyboardEvent) => {
  if (!modelValue.value || event.key !== '-' || props.hideSign) return
  event.preventDefault()
  if (
    (modelValue.value < 0 && props.alwaysNegative) ||
    (modelValue.value >= 0 && props.alwaysPositive)
  ) {
    showTooltip()
    return
  }
  modelValue.value *= -1
}

const showTooltip = () => {
  isTooltipShown.value = true
  setTimeout(() => {
    isTooltipShown.value = false
  }, 1500)
}

const handleReady = (el: any) => {
  inputRef.value = el?.$el as HTMLInputElement
  emit('ready', inputRef.value)
}

const { pause: formatedWatchPause, resume: formatedWatchResume } =
  watchPausable(formattedValue, updateModel)

const { pause: modelWatchPause, resume: modelWatchResume } = watchPausable(
  modelValue,
  updateInput,
  { immediate: true },
)
</script>
